import { cloneDeep, get, has, isEmpty, isString, pick } from "lodash";
import { mergeDeep } from "@apollo/client/utilities";
import { defineStore, storeToRefs } from "pinia";

type DataBucketOptions<T> = {
  id?: string;
  original?: T;
};

export const useDataBucketStore = <
  D extends any,
  K extends keyof D = keyof D,
  V extends D[K] = D[K],
>(
  options?: DataBucketOptions<D>
) => {
  const id = options?.id || uuid();

  const s = defineStore("data-bucket:" + id, () => {
    const defu = Object.assign({}, options?.original) as D;
    const original = ref<D>(cloneDeep(defu));
    const data = ref<D>(cloneDeep(defu));

    const changes = computed(() => deepDiff<any>(data.value, original.value));
    const isDirty = computed(() => !isEmpty(changes.value));

    const dataWithCurrent = (input: any): any => {
      const d = toRaw(data.value);
      return mergeDeep(d, input);
    };

    const fillOriginal = (attributes: D) => {
      if (!isEmpty(attributes)) {
        const d = dataWithCurrent(attributes);
        original.value = cloneDeep(d);
        data.value = cloneDeep(d);
      }
    };

    const setOriginal = (key: K, value: V): V => {
      fillOriginal({ [key]: value } as any);
      return value;
    };

    const getOriginal = (key: K, $default: V): V =>
      get(original.value, key, $default);

    const fill = (attributes: D) => {
      if (!isEmpty(attributes)) {
        data.value = dataWithCurrent(attributes);
      }
    };

    const fillDiff = (attributes: Partial<D>) => {
      const diff: any = deepDiff<any>(attributes, data.value);
      fill(diff);
    };

    const setData = (key: K, value: V): V => {
      fill({ [key]: value } as any);
      return value;
    };

    const getData = (key: K, $default?: V): V => get(data.value, key, $default);
    const hasData = (key: K) => has(data.value, key);

    const keepChanges = (attributes?: D) => {
      if (attributes) {
        fill(attributes);
      }
      original.value = cloneDeep(toRaw(data.value)) as any;
    };

    const getChanges = (): D => toRaw(changes.value) as any;

    const discardChanges = () => {
      data.value = cloneDeep(toRaw(original.value));
    };

    const createProxy = () => {
      // @ts-ignore
      return new Proxy({} as D, {
        set(target, prop: string, value: any) {
          setData(prop as K, value);
          return true;
        },
        get(target, prop: string, receiver) {
          if (isString(prop)) {
            return getData(prop as any);
          }
          return Reflect.get(target, prop, receiver);
        },
      });
    };

    // const access = <T>(path: string, defu?: T) => {
    //   return computed({
    //     set(value: T) {
    //       set(changes.value, path, value);
    //     },
    //     get(): T {
    //       if (!has(data.value, path) && !isUndefined(defu)) {
    //         set(changes.value, path, defu);
    //       }
    //       return get(data.value, path);
    //     },
    //   });
    // };

    const $reset = () => {
      original.value = cloneDeep(defu) as any;
      data.value = cloneDeep(defu) as any;
    };

    return {
      original,
      changes,
      data,
      isDirty,
      fillOriginal,
      setOriginal,
      getOriginal,
      set: setData,
      get: getData,
      has: hasData,
      fill,
      fillDiff,
      keepChanges,
      getChanges,
      discardChanges,
      createProxy,
      $reset,
    };
  });

  return s();
};

export const useDataBucket = <D extends any>(
  options?: DataBucketOptions<D>
) => {
  const s = useDataBucketStore(options);

  return {
    ...storeToRefs(s),
    ...pick(s, [
      "$reset",
      "fillOriginal",
      "setOriginal",
      "getOriginal",
      "set",
      "get",
      "has",
      "fill",
      "fillDiff",
      "keepChanges",
      "getChanges",
      "discardChanges",
      "createProxy",
    ]),
  };
};
