import dayjs, { Dayjs } from "dayjs";
import type { MetricsPeriod } from "../types/common";

export const now = () => dayjs();

export const splitTime = (time?: string) => {
  const [h, m, s] = (time || "").split(":").map(Number);

  return {
    hours: h || 0,
    minutes: m || 0,
    seconds: s || 0,
  };
};

/**
 * Parse time input into minutes.
 * Accepts: hh:mm am/pm', 'mm:ss', 'Xm Ys', or plain seconds.
 *
 * parseTime("10:30 am")  -> 630.00
 * parseTime("10:30 pm")  -> 1350.00
 * parseTime("90")        -> 1.50
 * parseTime("1:30")      -> 90
 * parseTime("1:30:45")   -> 90.75
 * parseTime("1m 30s")    -> 1.50
 * parseTime("45s")       -> 0.75
 */
export const parseTime = (input: string | undefined) => {
  if (!input) {
    return;
  }

  // Remove all spaces and convert the input to lowercase
  input = input.replace(/\s+/g, "").toLowerCase();

  // Initialize total minutes
  let totalMinutes = 0;

  const formattedToMinutes = (x: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    const mins = x.hours * 60 + x.minutes + x.seconds / 60;
    return wrapOffset(mins, 1440, 0);
  };

  // Check if the input is in "hh:mm am/pm" format
  if (/^\d{1,2}:\d{2}(am|pm)$/i.test(input)) {
    const time = input.slice(0, -2); // Extract "hh:mm" part
    const period = input.slice(-2); // Extract "am" or "pm"
    const splittedTime = splitTime(time);

    // Convert 12-hour time to 24-hour time
    if (period === "pm" && splittedTime.hours !== 12) splittedTime.hours += 12;
    if (period === "am" && splittedTime.hours === 12) splittedTime.hours = 0;

    totalMinutes = formattedToMinutes(splittedTime);
  } else if (/^\d{1,2}:\d{2}(?::\d{2})?$/.test(input)) {
    // Check if the input is in "HH:mm:ss" or "HH:mm" format
    const splittedTime = splitTime(input);
    totalMinutes = formattedToMinutes(splittedTime);
  } else if (/^(\d+m)?(\d+s)?$/.test(input)) {
    // Check for formats like "1m 30s"
    const minutesMatch = input.match(/(\d+)m/);
    const secondsMatch = input.match(/(\d+)s/);
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    const seconds = secondsMatch ? parseInt(secondsMatch[1], 10) : 0;
    totalMinutes = minutes + seconds / 60;
  } else if (/^\d+$/.test(input)) {
    // If the input is a plain number, assume it's in seconds
    const seconds = parseInt(input, 10);
    totalMinutes = seconds / 60;
  } else {
    return;
  }

  return totalMinutes;
};

export const parseTimeToDate_ = (
  input: string | undefined,
  offset?: dayjs.Dayjs
) => {
  const minutes = parseTime(input);

  const t = offset || now();

  if (minutes) {
    return t.startOf("day").add(minutes, "minutes");
  }

  return t;
};

export const parseTimeToDate = (
  time: string | undefined,
  offset?: dayjs.Dayjs
) => {
  const { hours, minutes, seconds } = splitTime(time);

  return (offset || now())
    .startOf("day")
    .add(hours, "hours")
    .add(minutes, "minutes")
    .add(seconds, "seconds");
};

export const dateMinutes = (date: DateLike) => {
  const d = parseDateLike(date);

  const h = d.hour();
  const m = d.minute();

  return h * 60 + m;
};

export const parseMetricsPeriod = (period: MetricsPeriod | string): Dayjs => {
  return match(period, {
    today: () => now(),
    // yesterday: () => now().subtract(1, "day"),
    "previous-period": () => now().subtract(1, "day"),
    "previous-hour": () => now().subtract(1, "hour"),
    "previous-day": () => now().subtract(1, "day"),
    "previous-week": () => now().subtract(1, "week"),
    "previous-month": () => now().subtract(1, "month"),
    "previous-quarter": () => now().subtract(3, "months"),
    "previous-year": () => now().subtract(1, "year"),
    default: () => dayjs(period),
  });
};

export const findRelevantDate = <T extends string | Dayjs>(dates: T[]) => {
  const n = now();
  let earliestFutureDate: Dayjs | undefined;
  let latestPastDate: Dayjs | undefined;

  dates.forEach((d) => {
    const date = dayjs(d);

    if (date.isAfter(n)) {
      if (!earliestFutureDate || date.isBefore(earliestFutureDate)) {
        earliestFutureDate = date;
      }
    } else {
      if (!latestPastDate || date.isAfter(latestPastDate)) {
        latestPastDate = date;
      }
    }
  });

  return earliestFutureDate ? earliestFutureDate : latestPastDate;
};

export const isActivePeriod = (start: DateLike, end: DateLike) => {
  const s = parseDateLike(start);
  const e = parseDateLike(end);
  const n = now();

  return (s.isSame(n) || s.isBefore(n)) && (s.isSame(n) || s.isAfter(n));
};
