<template>
  <div class="mb-3">
    <div class="grid grid-cols-12 md:grid-cols-14 gap-2">
      <div
        class="col-span-12 md:col-span-6"
        :class="{ 'col-span-6 md:col-span-8': isDiffEndDate }"
      >
        <v-date-input
          v-model="startDate"
          :disabled="disabled"
          :label="isDiffEndDate ? 'Start Date' : 'Date'"
          :max="startDate ? undefined : endDate"
          density="compact"
          variant="outlined"
          rounded="lg"
          prepend-inner-icon="calendar_today"
          prepend-icon=""
          hide-details
        />
      </div>
      <div
        class="col-span-6 md:col-span-4"
        :class="{ 'col-span-6 md:col-span-6': isDiffEndDate }"
      >
        <TimeInput
          v-model="startTime"
          :disabled="disabled"
          label="Start time"
        />
      </div>
      <div
        v-if="isDiffEndDate"
        class="col-span-12 md:col-span-6"
        :class="{ 'col-span-6 md:col-span-8': isDiffEndDate }"
      >
        <v-date-input
          ref="endDateRef"
          v-model="endDate"
          :disabled="disabled"
          :min="startDate"
          label="End Date"
          density="compact"
          variant="outlined"
          rounded="lg"
          prepend-inner-icon="calendar_today"
          prepend-icon=""
          hide-details
        />
      </div>
      <div
        class="col-span-6 md:col-span-4"
        :class="{ 'col-span-6 md:col-span-6': isDiffEndDate }"
      >
        <TimeInput
          v-model="endTime"
          :disabled="disabled"
          :min="isDiffEndDate ? undefined : startTime"
          label="End time"
        />
      </div>
    </div>
    <div class="text-red caption">
      <div v-for="(error, i) in errors" :key="i">{{ error }}</div>
    </div>
    <div v-if="!isDiffEndDate" class="mt-1">
      <v-btn @click="changeEndDate" variant="text" size="x-small"
        >Change end date</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { get } from "lodash";
import { defineComponent } from "vue";
import { VDateInput } from "vuetify/labs/VDateInput";

type DateInput = {
  startDate?: DateLike;
  startTime?: number;
  endDate?: DateLike;
  endTime?: number;
};

export default defineComponent({
  components: {
    VDateInput,
  },
  props: {
    modelValue: { type: Array as PropType<DateLike[]> },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: [String, Array] },
  },
  setup(props, ctx) {
    const input = ref<DateInput>({});
    const localError = ref<string>();
    const _useDiffEndDate = ref(false);
    const endDateRef = ref<InstanceType<typeof VDateInput>>();
    const { onDiscarded } = useSaveContext();

    onDiscarded(() => {
      input.value = {};
    });

    const encodeDatePeriod = (input: Required<DateInput>) => {
      const s = parseDateLike(input.startDate).startOf("day");
      const e = parseDateLike(input.endDate).startOf("day");
      return [
        s.set("minutes", input.startTime).toDate(),
        e.set("minutes", input.endTime).toDate(),
      ];
    };

    const getDate = (i: number) => {
      const d = get(props.modelValue, i);
      return d ? parseDateLike(d) : undefined;
    };

    const setInput = <K extends keyof DateInput>(
      key: K,
      value: DateInput[K]
    ) => {
      const dateInput = Object.assign({}, input.value);
      dateInput[key] = value;

      const x1 = getDate(0);
      const x2 = getDate(1);

      const startDate = dateInput.startDate || x1?.toDate();
      const endDate = dateInput.endDate || x2?.toDate() || startDate;
      const startTime =
        dateInput.startTime || (x1 ? dateMinutes(x1) : undefined);
      const endTime = dateInput.endTime || (x2 ? dateMinutes(x2) : undefined);

      if (startDate && endDate && startTime && endTime) {
        const dates = encodeDatePeriod({
          startDate,
          endDate,
          startTime,
          endTime,
        });

        if (dates[0].getTime() >= dates[1].getTime()) {
          localError.value = "End time must be after start time";
        } else {
          localError.value = "";
          ctx.emit("update:modelValue", dates);
        }
      } else {
        localError.value = "";
      }

      input.value = dateInput;
    };

    const isDiffEndDate = computed(() => {
      return (
        _useDiffEndDate.value ||
        execFn(() => {
          const x1 = getDate(0)?.startOf("day");
          const x2 = getDate(1)?.startOf("day");

          return x1 && x2 && !x1.isSame(x2, "day");
        })
      );
    });

    const startDate = computed({
      set(value: DateLike | undefined) {
        setInput("startDate", value);

        if (!isDiffEndDate.value) {
          setInput("endDate", value);
        }
      },
      get() {
        if (input.value.startDate) {
          return input.value.startDate;
        }
        const x = get(props.modelValue, 0);
        if (x) {
          input.value.startDate = parseDateLike(x).toDate();
          return input.value.startDate;
        }
      },
    });

    const endDate = computed({
      set(value: DateLike | undefined) {
        setInput("endDate", value);
      },
      get() {
        if (input.value.endDate) {
          return input.value.endDate;
        }
        const x = get(props.modelValue, 1);
        if (x) {
          input.value.endDate = parseDateLike(x).toDate();
          return input.value.endDate;
        }
      },
    });

    const startTime = computed({
      set(value: number | undefined) {
        setInput("startTime", value);
      },
      get() {
        if (input.value.startTime) {
          return input.value.startTime;
        }
        const x = get(props.modelValue, 0);
        if (x) {
          input.value.startTime = dateMinutes(x);
          return input.value.startTime;
        }
      },
    });

    const endTime = computed({
      set(value: number | undefined) {
        setInput("endTime", value);
      },
      get() {
        if (input.value.endTime) {
          return input.value.endTime;
        }
        const x = get(props.modelValue, 1);
        if (x) {
          input.value.endTime = dateMinutes(x);
          return input.value.endTime;
        }
      },
    });

    const changeEndDate = () => {
      _useDiffEndDate.value = true;
      nextTick(() => {
        if (endDateRef.value) {
          const input = endDateRef.value.$el.querySelector("input");
          input.click();
        }
      });
    };

    return {
      startDate,
      endDate,
      startTime,
      endTime,
      localError,
      isDiffEndDate,
      endDateRef,
      changeEndDate,
    };
  },
  computed: {
    errors() {
      if (this.localError) {
        return [this.localError];
      }

      return wrap(this.errorMessages || []);
    },
  },
});
</script>
