<template>
  <div class="relative">
    <v-autocomplete
      ref="refInput"
      v-model="timeManual"
      :disabled="disabled"
      :label="label"
      :items="items"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      density="compact"
      variant="outlined"
      rounded="lg"
      item-title="display"
      item-value="time"
      prepend-inner-icon="schedule"
      hide-no-data
      @input="setTime"
      @keyup.enter="onEnter"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { timeItems } from "../private/opening-hours/utils";
import { useVModel } from "@vueuse/core";
import { isNumber } from "lodash";

export default defineComponent({
  name: "TimeInput",
  props: {
    modelValue: { type: [String, Number], default: null },
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    min: { type: [String, Number], default: null },
    max: { type: [String, Number], default: null },
    use24hrs: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: true },
    errorMessages: {
      type: [Array, String] as PropType<string[] | string>,
      default: null,
    },
  },
  setup(props) {
    const time = useVModel(props);
    const refInput = ref<any>();

    const items = computed(() => {
      const mins = timeItems(
        withValue(props.min, (v) => Number(v)),
        withValue(props.max, (v) => Number(v)),
        props.use24hrs,
        30
      );

      return mins.map((item) => ({
        ...item,
        active: item.time == time.value,
      }));
    });

    const setTime = (input?: Event | number) => {
      if (isNumber(input)) {
        time.value = input;
      } else if (input) {
        const value = (input.target as HTMLInputElement).value;
        const parsedTime = parseTime(value);
        if (parsedTime) {
          time.value = parsedTime;
        }
      }
    };

    const onEnter = () => {
      if (refInput.value?.blur) {
        refInput.value.blur();
      }
    };

    const timeManual = computed({
      set: setTime,
      get(): string | undefined {
        if (props.modelValue) {
          return formatMinutes(Number(props.modelValue), props.use24hrs);
        }
      },
    });

    return {
      timeManual,
      items,
      refInput,
      setTime,
      onEnter,
    };
  },
});
</script>
