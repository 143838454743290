import { isNumber } from "lodash";

export const min = (n: number[], clamp?: number) => {
  const lim = Math.min(...n);
  if (isNumber(clamp)) {
    return Math.max(lim, clamp);
  }
  return lim;
};

export const max = (n: number[], clamp?: number) => {
  const lim = Math.max(...n);
  if (isNumber(clamp)) {
    return Math.min(lim, clamp);
  }
  return lim;
};

export const wrapOffset = (n: number, max: number, decrement = 1): number => {
  if (n > max) {
    return wrapOffset(n - max - decrement, max, decrement);
  }
  return n;
};
