<template>
  <div class="border rounded-lg overflow-hidden">
    <v-tabs
      v-model="controls.tab"
      :mobile="mobile"
      :direction="mobile ? 'horizontal' : 'vertical'"
      density="compact"
    >
      <Draggable
        v-model="items"
        :group="contentId"
        item-key="key"
        handle=".handle"
        class="md:flex md:flex-col"
      >
        <template #item="{ element, index }">
          <v-tab
            :key="element.key"
            :value="element.key"
            class="tab px-0 text-none relative"
            rounded="0"
          >
            <div
              class="md:absolute md:inset-0 text-left flex-align pr-2 gap-2"
              :class="{
                'pl-2': shouldSort,
                'pl-4': !shouldSort,
              }"
            >
              <GrabHandle v-if="shouldSort" class="handle" />
              <span v-if="element.title" class="text-left text-truncate">
                {{ element.title }}
              </span>
              <span v-else>Item {{ index + 1 }}</span>
              <v-spacer />
              <v-icon v-if="!element.valid" color="red" title="Has errors"
                >error_outline</v-icon
              >
              <v-btn
                v-if="canRemove"
                @click.stop="remove(element.item)"
                size="30"
                variant="text"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </v-tab>
        </template>
      </Draggable>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { injectSectionContentStore } from "../lib";
import { useDisplay } from "vuetify";
import Draggable from "vuedraggable";
import { cloneDeep } from "lodash";

export default defineComponent({
  components: { Draggable },
  props: {
    canRemove: { type: Boolean, default: false },
  },
  setup() {
    const { id, controls, displayItems, display, remove, set } =
      injectSectionContentStore();
    const { mobile } = useDisplay();
    type Items = typeof displayItems.value;

    const shouldSort = computed(() => {
      return controls.value.sortable && !mobile.value;
    });

    const items = computed({
      set(value: Items) {
        let items = value.map((item) => cloneDeep(item.item));
        if (controls.value.sortable) {
          items = items.map((item, i) => ({
            ...item,
            [controls.value.sortBy]: i,
          }));
        }
        set(items);
      },
      get() {
        if (controls.value.sortable) {
          const sortByKey = "item." + controls.value.sortBy;
          return sortBy<string, any>(displayItems.value, sortByKey);
        }
        return displayItems.value;
      },
    });

    return {
      contentId: id,
      controls,
      items,
      mobile,
      display,
      shouldSort,
      remove,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab:not(:last-child) {
  @media only screen and (min-width: 768px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media only screen and (max-width: 767px) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.dragged-tab,
.sortable-chosen {
  @apply dragged-item;
}
.tab {
  max-width: 800px !important;
}
</style>
